exports.isValidPhoneNumber = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(value);
};

exports.isValidEmail = string => {
  if (typeof string === 'undefined' || string === null || string === '') {
    return null;
  }

  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegExp.test(string)
};